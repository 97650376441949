import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconComponent } from './components/icon/icon.component';
import { MatProgressBarModule } from '@angular/material/progress-bar'; // mat - progress bar
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpTranslateLoader } from '../app.module';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { UtilityService } from './services/utility.service';
import { TableComponent } from './components/table/table.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FilterComponent } from './components/filter/filter.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoaderInterceptorService } from './services/loader-interceptor.service';
import { GenericAlertComponent } from './components/generic-alert/generic-alert.component';
import { GenericInfoComponent } from './components/generic-info/generic-info.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    IconComponent,
    ButtonsComponent,
    TableComponent,
    FilterComponent,
    PageNotFoundComponent,
    GenericAlertComponent,
    GenericInfoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule,
    MatTabsModule,
    MatProgressBarModule,
    MatDialogModule,
    ToastrModule.forRoot(), // ToastrModule added
    MatTableModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatTooltipModule,
    MatAutocompleteModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    MatSidenavModule,
    MatListModule,
    MatTabsModule,
    IconComponent,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ToastrModule, // ToastrModule exported
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatSelectModule,
    MatIconModule,
    ButtonsComponent,
    TableComponent,
    MatCardModule,
    MatRadioModule,
    MatTooltipModule,
    FilterComponent,
    MatAutocompleteModule,
  ],
  providers: [
    UtilityService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
   
  ],
})


export class SharedModule {}

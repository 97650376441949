import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  // Input property for mat-table data-source
  @Input() dataSource: any;

  // Property for display columns data
  @Input() displayedColumns: any;

  // Proerty for header and style
  @Input() columns: any;

  // Create new event prooperty to communticate to parent component
  @Output() sendEvent = new EventEmitter<any>();

  isOverflowing: boolean = false;

  visible: boolean = true;

  // status
  status: boolean = false;
  isShowTimePopUp: boolean = false;

  @Input() selectedDate: any;
  /**/
  @Input() manuallyAddedText = true;

  // Proerty for header and style
  @Input() useruuId!:string;

  constructor(public dialog: MatDialog) {}

  @Input() isShimmer: boolean = false;

  displayedShimmerColumns: string[] = [
    'position',
    'post',
    'name',
    'weight',
    'symbol',
  ];
  shimmerDataSource = [
    { position: '', post: '', name: '', weight: '', symbol: '' },
    { position: '', post: '', name: '', weight: '', symbol: '' },
    { position: '', post: '', name: '', weight: '', symbol: '' },
    { position: '', post: '', name: '', weight: '', symbol: '' },
    { position: '', post: '', name: '', weight: '', symbol: '' },
    { position: '', post: '', name: '', weight: '', symbol: '' },
  ];

  ngOnInit(): void {}

  /**
   * Show the tooltip on ellipse
   * @param element --> Getting element on mouse hover
   */
  showHidetooltip(element: any): void {
    if (element.target.scrollWidth <= element.target.clientWidth) {
      this.isOverflowing = true;
    } else {
      this.isOverflowing = false;
    }
  }

  /**
   * Edit the data when this function called
   * @param event --> mat-table data object
   */
  sendRow(event: any, type: any, index?: number): void {
    this.sendEvent.emit({ event, type, index });
  }

}

import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isMenuShowing: boolean;
  headerData: any;
  constructor(private commonService: CommonService, 
    private router: Router) {
    this.isMenuShowing = false;
  }

  ngOnInit(): void {
    this.commonService.headerData.subscribe((res) => {
      this.headerData = res;
    });
  }
  /**
   * show/hide side menu button click
   */
  navToggle() {
    if (this.isMenuShowing) {
      this.isMenuShowing = false;
      this.commonService.showHideSideMenu.next(false); /// false -> hide menu
    } else {
      this.isMenuShowing = true;
      this.commonService.showHideSideMenu.next(true); /// true -> show menu
    }
  }

  /**
  * Route to url using click
  */
  breadcrumbrouting(url:any):any{
    if(url != null && url != ''){
     return this.router.navigate(['pages/'+url]);
    }
  }
}

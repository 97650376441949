<div class="filter-wrapper">
  <div class="filter-w">
    <div class="dialog-header row">
      <h1 class="filter-title">Filters</h1>
      <div class="action-btn">
        <custom-buttons shape="outline" (click)="sendFalseValue()"
          >Reset</custom-buttons
        >
        <custom-buttons
          color="primary"
          fill="#fff"
          iconSlot="start"
          (click)="sendTrueValue()"
          >Apply</custom-buttons
        >
      </div>
    </div>
    <ng-container *ngTemplateOutlet="customTemplate"> </ng-container>
  </div>
</div>

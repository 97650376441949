import { Injectable } from '@angular/core';
import { ApiInterfaceServices } from './api-interface.service';
import { firstValueFrom } from 'rxjs';
import { AppConfig } from 'src/app/app-config';
import { user } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiInterfaceServices
    ) {}

  /**
   * get ALL users Listing data!!!
   */
  async getUserListing(objUser:user) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService.get(AppConfig.USERS.USER, true,objUser)
      ));
      if (result) {
        return result;
      }
      return false;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

   /**
   * Makes a reset password request.
   * @param objResetPassword - User's uuid.
   */
   async resetPassword(id: any) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService
          .put(AppConfig.USERS.RESET_PASSWORD+"/"+id,'',true)
          
      ));
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

   /**
   * add user data.
   * @param objUserData - User's uuid.
   */
   async addUserDetails(objUserData: user) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService
          .post(AppConfig.USERS.USER, objUserData, true)
      ));
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

     /**
   * 
   * @param obj - User's token.
   */
     async authenticateUser(obj: user) {
      try {
        const result = await (<any> firstValueFrom(
          this.apiService
            .post(AppConfig.USERS.USER + "/" + AppConfig.USERS.AUTHENTICATE, obj, true)
        ));
        return result;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  /**
   * Get employee Status from backend
   * @param userObj Object for sending current employeestatus active/deactive
   * @param id Uuid of the emplopyee
   * @returns current status of the employee
   */
  async updateUserStatus(userObj: any, Id: string) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService
          .patch(AppConfig.USERS.USER + "/" + Id, userObj, true)
          
      ));
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Get Country List data
   * @returns Data of Country
   */
 async getCountryList() {
  try {
    const result = await (<any> firstValueFrom(
      this.apiService
        .get(AppConfig.USERS.COUNTRY, true)
    ));
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

  /**
   * Get State Data  data
   * @returns Data of State
   */
  async getStateData(Obj:any) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService
          .post(AppConfig.USERS.COUNTRY + "/" +AppConfig.USERS.STATE,Obj, true)
      ));
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
 /**
   * Get User Data By UUId
   * @returns Data of User Data
   */
 async getUserDataByUuid(Id: any) {
  try {
    const result = await (<any> firstValueFrom(
      this.apiService
        .get(AppConfig.USERS.USER + '/' + Id, true)
    ));
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}
   /**
   * Update User Data.
   * @param objUserData - User's uuid.
   */
   async updateUserDetails(objUserData: user,uId:string) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService
          .put(AppConfig.USERS.USER + '/' + uId, objUserData, true)
      ));
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousGuardService } from './shared/services/anonymous-guard.service';
import { AuthGuard } from './shared/components/guard/auth.guard';
import { UserActivationComponent } from './pages/user-management/user-activation/user-activation.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AnonymousGuardService],
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard],

  },
  {
    path:'activate/user',
    component:UserActivationComponent
  },
  {
    path:'pagenotfound',
    component:PageNotFoundComponent
  },
  {
    path: '**',
    component:PageNotFoundComponent,
    pathMatch:'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {


  constructor(
    private router:Router,
    private authService:AuthService) { }

  ngOnInit(): void {
  }

   //back button to redirect on pages dashboard!!
   redirect() {
    if(this.authService.isUserLoggedIn()){
      this.router.navigate(['/pages/dashboard']);
    }else{
      this.router.navigate(['/login']);
    }
  }

}

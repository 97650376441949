
<div class="modal-outer generic-creation-modal">
    <div mat-dialog-title class="title">
      <h3>
          {{data.headingTitle}}
      </h3>
      <button mat-button mat-dialog-close class="close">
        <app-icon name="modal-close-icon" class="svg-icon"> </app-icon>
      </button>
  </div>
    <div mat-dialog-content>
          <ol class="key-value">
              <li *ngFor="let details of data.array">                    
                  <label for="{{details.key}}">{{details.key}} </label>
                  <span >{{details.value || "-" }}</span>
              </li>
          </ol>
    </div>
  </div>
  
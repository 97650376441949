<div class="modal-outer generic-creation-modal">
    <h1 mat-dialog-title class="modal-title">
      {{data.modalTitle}}
      <button mat-button mat-dialog-close class="close">
        <app-icon name="modal-close-icon" class="svg-icon"> </app-icon>
      </button>
    </h1>
    <div mat-dialog-content class="modal-content">
      <div class="details">
        <p>
          <strong>{{ data.headingMsg }}</strong>
        </p>
        <div class="action-button">
          <div
            mat-dialog-actions
            *ngFor="let button of data.buttons; let i = index"
          >
            <button
              mat-flat-button
              [ngClass]="i == 0 ? 'primary-btn' : 'secondary-btn'"
              [color]="i === 0 ? 'primary' : ''"
              (click)="close(button.value)"
            >
              {{ button.name | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
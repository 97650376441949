<button
  mat-flat-button
  class="tb-btn d-flex"
  size="size"
  fill="fill"
  type="button"
  [disableRipple]="isrippleeffect"
  [color]="color"
  [disabled]="isDisable"
  [ngClass]="[
    shape === 'not-rounded'
      ? 'not-rounded'
      : shape === 'icononly-rounded'
      ? 'icononly-rounded'
      : shape === 'icononly'
      ? 'icononly'
      : shape === 'link'
      ? 'hyperlink'
      : shape === 'outline'
      ? 'outline'
      : 'not-rounded',
      btnstyle === 'mat-raised-button' ? 'mat-mdc-raised-button' : 'mat-mdc-flat-button',
    size === 'small' ? 'small' : ''
  ]"
  (click)="btnClick(matButton)"
  #matButton
  *ngIf="isShimmer == false"
>

  <mat-label
    class="tb-btn-lable"
    [ngClass]="[size == 'small' ? 'tb-fw-regular' : 'tb-fw-semibold']"
  >
  </mat-label>
  <!-- button default state -->
  <ng-container *ngIf="state === 'defaultState'">
    <span *ngIf="badge" [matBadge]="badge" matBadgeColor="primary"></span>
    <app-icon
      *ngIf="icon"
      [name]="icon"
      [fill]="fill"
      class="tb-svg-icon"
    ></app-icon>
    <!-- The <ng-content> element specifies where to project content inside a component template. -->
    <ng-content></ng-content>
  </ng-container>

  <!-- button loding state -->
  <ng-container *ngIf="state === 'loadingState'">
    <mat-spinner
      class="loading-mat-spinner"
      diameter="16"
      strokeWidth="2"
    ></mat-spinner>
    <span class="loading-state-title">{{ btnTitle }}</span>
  </ng-container>

  <!-- button success state -->
  <ng-container *ngIf="state === 'successState'">
    <app-icon
      name="checkmark"
      [fill]="fill"
      class="tb-svg-icon status-icon"
    ></app-icon>
    {{ btnTitle }}
  </ng-container>

  <!-- button failed state -->
  <ng-container *ngIf="state === 'failedState'">
    <app-icon
      name="failed"
      [fill]="fill"
      class="tb-svg-icon status-icon"
    ></app-icon>
    {{ btnTitle }}
  </ng-container>

</button>

import { Injectable } from '@angular/core';
import { sideMenuData } from '../models/sidemenu.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HeaderData } from '../models/header.model';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  /**
   * Observable for show/hide Side Menu
   */
  showHideSideMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  /**
   * Observable for show/hide loader
   */
  showHideLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  successAfterServiceCalling: Subject<boolean> = new Subject<boolean>();
  succesFullTimeLogAdded: Subject<boolean> = new Subject<boolean>();
  headerData: BehaviorSubject<HeaderData> = new BehaviorSubject<HeaderData>({
    label: 'DASHBOARD',
      icon: 'dashboardIcon',
      breadcrumbs: [
        {
          label: 'SIDEBAR.DASHBOARD',
          url: '',
        },
      ],
  });
  arrSidemenuData: sideMenuData[];
  constructor(
    private toastr: ToastrService,
    private utilityService: UtilityService
  ) {
    this.arrSidemenuData = [];
  }
 
  /**
   * load side menu data and sent data
   * @returns sideMenuData[] = sideMenu data modal with options
   */
  getSidemenuData(): sideMenuData[] {
    let user_uuid = this.getUserData()?.uuid;

    /*
     * Settings Object
     */
    const objSettings = [];
    objSettings.push({
      icon: 'settings',
      name: 'CHANGE_PASSWORD',
      urls: '/pages/change-password/' + user_uuid,
    },
    {
      icon: 'settings',
      name: 'LOGOUT',
      urls: '.',
    });

    this.arrSidemenuData = [
      {
        icon: 'dashboard',
        name: 'DASHBOARD',
        urls: '/pages/dashboard',
        options: [],
      },
      {
        icon: 'user',
        name: 'USERSLIST',
        urls: '/pages/user-management/users-list',
        options: [],
      },
      {
        icon: 'data',
        name: 'DATAMANAGEMENT',
        urls: '/pages/data-management/list-data',
        options: [],
      },
      {
        icon: 'language',
        name: 'LANGUAGELIST',
        urls: '/pages/language-management/language-list',
        options: [],
      },
      {
        icon: 'settings',
        name: 'SETTINGS',
        options: objSettings,
      }
    ];
 
    return this.arrSidemenuData;
  }

  /**
   * Show Loader
   */
  showLoader() {
    this.showHideLoader.next(true);
  }

  /**
   * Hide Loader
   */
  hideLoader() {
    this.showHideLoader.next(false);
  }

  /**
   * show success toaster
   * @param title -> title for toaster
   * @param message -> message for toaster
   * @param isShowCloseButton -> close button show/hide default true
   */
  showSuccessToaster(title: string, message: string, isShowCloseButton = true) {
    this.toastr.success(message, title, {
      closeButton: isShowCloseButton,
      tapToDismiss: false,
    });
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  /**
   * show error toaster
   * @param title -> title for toaster
   * @param message -> message for toaster
   * @param isShowCloseButton -> close button show/hide default true
   */
  showErrorToaster(title: string, message: string, isShowCloseButton = true) {
    this.toastr.error(message, title, {
      closeButton: isShowCloseButton,
      tapToDismiss: false,
    });
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  /**
   *
   * @param error Error Object
   */
  showErrorMessage(error:any){
    if(error){
      this.showErrorToaster('error', error);
    }
  }

 
  /**
   * get User data from localstorage
   */
  getUserData() {
    let userData = this.utilityService.getLocalStorageData('userData');
    return userData;
  }
}

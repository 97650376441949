<div class="sidebar-max-width">
  <div class="sidebar-header theme-bg-color row">
    <!-- <a href="#" class="img-logo-link">
      <img src="./assets/images/128x128.png" />
    </a> -->
    <div class="circle">
      <img src="./assets/images/LOGO.jpg" />

      <!-- <h2 class="profile-pic">{{userProfileText}}</h2> -->
    </div>
  </div>
  <div class="sidebar-content col w-100 margin-top">
    <h2 class="text-center tb-fs-lg-default">{{displayUserName}}</h2>
    <p class="profile-gray-text-secondry-color text-center tb-fs-md">{{userRole}}</p>
    <div class="margin-right-left"></div>
    <ul class="sidebar-main-nav col">
      <li class="nav-items" *ngFor="let nav of arrSidemenuData; let i = index">
        <a
          class="nav-item-link row"
          (click)="showHideDropDown(i)"
          [routerLink]="nav.urls"
          [ngClass]="showActiveMenuIndex == i ? 'active' : ''"
        >
          <app-icon [name]="nav.icon" class="theme-svg-icon"> </app-icon>{{
          "SIDEBAR." + nav.name | translate }}
          <span class="dropdown-icon" *ngIf="nav.options?.length !== 0">
            <img
              [ngClass]="showSubMenuIndex === i ? 'rotate-90' : ''"
              src="./assets/images/Dropdown.svg"
            />
          </span>
        </a>
        <ul
          class="sub-nav-level-2"
          *ngIf="nav.options?.length !== 0 && showSubMenuIndex == i"
        >
          <li class="nav-items" *ngFor="let submenu of nav.options; let submenuindex = index">
            <a
              class="nav-item-link row"
              (click)="activeSubMenuData(submenu,submenuindex,i)"
              [routerLink]="submenu.urls"
              [queryParams]="submenu?.params"
              [ngClass]="activeSubMenuIndex == submenuindex ? 'active' : ''"
            >
              {{ "SIDEBAR." + submenu.name | translate }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'custom-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit {
  // Input method for showing loader button
  @Input() isLoading!: boolean;

  // State varibale for managing the button default, loading, success or fauiler state
  state: string;

  // Input method for providing the color to the button, By default it is primary color
  @Input() color = '';

  // Provide the icon to the button, show icon at button (filter, +(add), loader)
  @Input() icon = '';

  // This input Property used to show the counts on the button
  @Input() badge = '';

  // Provide the size of the button from the parent component
  @Input() size = '';

  // Input method for shape of the button
  @Input() shape!:
    | 'rounded'
    | 'not-rounded'
    | 'icononly-rounded'
    | 'link'
    | 'icononly'
    | 'outline';

  // Input method for the colour of the icon into button
  @Input() fill = 'primary';

  // Input method for the type of the button
  @Input() btnstyle!: 'mat-raised-button' | 'mat-flat-button';

  // Disabled ripple for breadcurmb
  @Input() isrippleeffect ="false";

  //Disabled the button
  @Input() isDisable = false;

  @Input() type!:string;

  // Define the inner-text of button
  btnText: any = '';

  // Define the button title with string Inter-Polation
  btnTitle: string = '';

  @ViewChild('matButton', { static: false, read: ElementRef })
  matButton!: ElementRef;

  @Input() isShimmer:boolean=false; //For Shimmer Effect

  constructor(private commonservice: CommonService) {
    // Set state to default state
    this.state = 'defaultState';
  }

  ngOnInit(): void {}

  /**
   * This function will be called on button click and will manage the functionality
   */
  btnClick(buttonData: any): void {
    // Change the button state when the user want to show the loader button
    if (this.isLoading) {
      this.state = 'loadingState';

      // Define the current button text to the btnText variable
      this.btnText = buttonData._elementRef.nativeElement.innerText;

      if (this.btnText === this.matButton.nativeElement.innerText) {
        switch (this.btnText === this.matButton.nativeElement.innerText) {
          case this.btnText === 'Save':
            this.btnTitle = 'Saving';
            break;
          case this.btnText === 'Submit':
            this.btnTitle = 'Submitting';
            break;
          case this.btnText === 'Sign In':
            this.btnTitle = 'Signing In';
            break;
          case this.btnText === 'Update':
            this.btnTitle = 'Updating';
            break;
          case this.btnText === 'Log In':
            this.btnTitle = 'Logging In';
            break;
        }
      }

      // This observale used to get API Success and failed response when button will be called
      let serviceCalled =
        this.commonservice.successAfterServiceCalling.subscribe(
          (isSuccess: boolean) => {
            if (isSuccess) {
              this.stopBtnSuccessAnimation();
            } else {
              this.stopBtnFailAnimation();
            }
            serviceCalled.unsubscribe();
          }
        );
    }
  }

  /**
   * Function for changing the state of button from loading to success when the API response is success
   */
  stopBtnSuccessAnimation() {
    this.state = 'successState';

    // change the text based on the button inner text
    if (this.btnText === 'Save') {
      this.btnTitle = 'Saved';
    } else if (this.btnText === 'Update') {
      this.btnTitle = 'Updated';
    } else if (this.btnText === 'Sign In') {
      this.btnTitle = 'Signed In';
    } else if (this.btnText === 'Log In') {
      this.btnTitle = 'Logged In';
    } else {
      this.btnTitle = 'Submitted';
    }

    setTimeout(() => {
      this.state = 'defaultState';
      if (this.btnText === 'Save') {
        this.btnTitle = 'Save';
      } else if (this.btnText === 'Update') {
        this.btnTitle = 'Update';
      } else if (this.btnText === 'Sign In') {
        this.btnTitle = 'Sign In';
      } else if (this.btnText === 'Log In') {
        this.btnTitle = 'Log In';
      } else {
        this.btnTitle = 'Submit';
      }
    }, 3000);
  }

  /**
   *  Function for changing the state of button from loading to fail when the API response is fail
   */
  stopBtnFailAnimation() {
    this.state = 'failedState';

    this.btnTitle = 'Failed';

    setTimeout(() => {
      this.state = 'defaultState';
      if (this.btnText === 'Save') {
        this.btnTitle = 'Save';
      } else if (this.btnText === 'Update') {
        this.btnTitle = 'Update';
      } else if (this.btnText === 'Sign In') {
        this.btnTitle = 'Sign In';
      } else if (this.btnText === 'Log In') {
        this.btnTitle = 'Log In';
      } else {
        this.btnTitle = 'Submit';
      }
    }, 3000);
  }
}

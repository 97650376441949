import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss']
})
export class UserActivationComponent {

  token:string;
  userMessage:string="Please Wait....";
  constructor(
    private commonService: CommonService,
    private activateRoute:ActivatedRoute,
    private userService:UserService,
  ) {

    this.token = this.activateRoute.snapshot.queryParams['token'];

    let Obj={
      token:this.token
    }
    this.authenticateUser(Obj);
  }

   /**
     * This function will add the data to backend
     * @param obj --> Object of user data
     */
   async authenticateUser(obj: any): Promise<void> {
    try {
      const result = await this.userService.authenticateUser(obj);
      if (result) {
        this.userMessage =result?.message;
      }
    } catch (error:any) {
      this.commonService.showErrorMessage(error);
    }
  }
}

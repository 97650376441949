import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogData } from '../../models/AlertDialogData';

@Component({
  selector: 'app-generic-alert',
  templateUrl: './generic-alert.component.html',
  styleUrls: ['./generic-alert.component.scss']
})
export class GenericAlertComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
  public dialogRef: MatDialogRef<GenericAlertComponent>
  ) { }

  /**
   *
   * @param value for getting button value for close that buton
   */
  close(value:string):void{
    this.dialogRef.close(value);
  }
}

<table
  mat-table
  [dataSource]="dataSource"
  class="w-100"
  *ngIf="!isShimmer"
>
  <!--
  Define the column name
  -->
  <ng-container
    *ngFor="let column of columns let index = index;"
    [matColumnDef]="column?.columnDef"
  >
    <!-- mat-table header data -->
    <th mat-header-cell ngClass="{{ column?.style }}" style.width="{{column?.inlinestyle}}" *matHeaderCellDef>
      <span class="ellipsis">{{ column?.header }}</span>
    </th>

    <!-- mat-table data -->
    <td
      mat-cell
      ngClass="{{ column?.style }}"
      style.width="{{column?.inlinestyle}}"
      *matCellDef="let row; let i = index"
    >
      <!-- This section contains data without some actionstype -->

      <ng-container *ngIf="!column?.actionType">
        <span
          [matTooltip]="row[column?.columnDef]"
          (mouseenter)="showHidetooltip($event)"
          [matTooltipDisabled]="isOverflowing"
          class="ellipsis"
          >{{ row[column?.columnDef] || "-" }}</span
        >
      </ng-container>
      <!-- This section will render data with actiontype checkbox -->
      <ng-container *ngIf="column?.actionType === 'columnName'";>
        {{ row['module_name'] || "-" }}
      </ng-container>
      <!-- This section will render data with actiontype checkbox / end-->
      <!-- This section will render data with action type editLink -->
      <ng-container *ngIf="column?.actionType === 'editLink'">
        
        
          <span
            [matTooltip]="row[column?.columnDef]"
            (mouseenter)="showHidetooltip($event)"
            [matTooltipDisabled]="isOverflowing"
            class="ellipsis"
            >{{ row[column?.columnDef] || "-" }}</span
          >
          <ng-container *ngIf="column.header == 'In'">
            <span *ngIf="row.is_manual_in" class="tb-fs-sm gray">Manually time added</span>
          </ng-container>
          <ng-container *ngIf="column.header == 'Out'">
            <span *ngIf="row.is_manual_out" class="tb-fs-sm gray">Manually time added</span>
          </ng-container>
        
      </ng-container>
      <!-- This section will render data with action types editLink-->
      <!-- This section will render data with action types -->
      <ng-container *ngIf="column?.actionType === 'hyperLink'">
        <!-- For remove underline from blank or - value in hyperlink  -->
        <ng-container *ngIf="row[column?.columnDef] == '-'; else elseBlock">
          <span
            [matTooltip]="row[column?.columnDef]"
            (mouseenter)="showHidetooltip($event)"
            [matTooltipDisabled]="isOverflowing"
            class="ellipsis"
          >
            {{ row[column?.columnDef] || "-" }}</span
          >
        </ng-container>
        <ng-template #elseBlock>
          <a (click)="sendRow(row, column?.actionType)">
            <span
              [matTooltip]="row[column?.columnDef]"
              (mouseenter)="showHidetooltip($event)"
              [matTooltipDisabled]="isOverflowing"
              class="ellipsis"
              >{{ row[column?.columnDef] || "-" }}</span
            ></a
          >
        </ng-template>
      </ng-container>

      <!-- For status -->
      <ng-container *ngIf="column?.actionType === 'statusNotClicked'">
        <custom-buttons
          mat-raised-button
          [ngClass]="
            row[column.columnDef] === 'REJECTED'
              ? 'fail'
              : row[column.columnDef] == 'APPROVED'
              ? 'success'
              : row[column.columnDef] == 'PENDING'
              ? 'pending'
              : row[column.columnDef] == 'CANCELLED'
              ? 'cancelled'
              : row[column.columnDef] == 'PARTIALLY APPROVED'
              ? 'partially-approved'
              : row[column.columnDef] == 'ACTIVE'
              ? 'success'
              : row[column.columnDef] == 'DEACTIVE'
              ? 'fail'
              : ''
          "
          >{{ row[column.columnDef] }}</custom-buttons
        >
      </ng-container>

      <!-- For status -->
      <ng-container *ngIf="column?.actionType === 'status'">
        <custom-buttons
          mat-raised-button
          [ngClass]="
            row[column.columnDef] === 'REJECTED'
              ? 'fail'
              : row[column.columnDef] == 'APPROVED'
              ? 'success'
              : row[column.columnDef] == 'PENDING'
              ? 'pending'
              : row[column.columnDef] == 'CANCELLED'
              ? 'cancelled'
              : row[column.columnDef] == 'PARTIALLY APPROVED'
              ? 'partially-approved'
              : row[column.columnDef] == 'ACTIVE'
              ? 'success'
              : row[column.columnDef] == 'DEACTIVE'
              ? 'fail'
              : ''
          "
          (click)="sendRow(row, column.actionType)"
        >
          <span
            [matTooltip]="row[column?.columnDef]"
            (mouseenter)="showHidetooltip($event)"
            [matTooltipDisabled]="isOverflowing"
            class="ellipsis"
            >{{ row[column.columnDef] || "-" }}</span
          >
        </custom-buttons>
      </ng-container>

      <!-- This section contains action icons -->
      <ng-container *ngIf="column?.actionType === 'delete'">
        <ng-container *ngFor="let action of column?.actions">
          <app-icon
            name="{{ action?.iconName }}"
            class="theme-svg-icon"
            (click)="sendRow(row, action?.type, i)"
          ></app-icon>
        </ng-container>
      </ng-container>

         <!-- This section contains action icons -->
         <ng-container *ngIf="column?.actionType === 'download'">
          <ng-container *ngFor="let action of column?.actions">
            <app-icon
              name="{{ action?.iconName }}"
              class="theme-svg-icon"
              (click)="sendRow(row, action?.type, i)"
            ></app-icon>
          </ng-container>
        </ng-container>


      <!-- This section contains action icons -->
      <ng-container *ngIf="column?.actionType === 'edit'">
        <ng-container *ngFor="let action of column?.actions">
          <app-icon
            name="edit"
            class="theme-svg-icon"
            (click)="sendRow(row, action?.type, i)"
          ></app-icon>
        </ng-container>
      </ng-container>

      <!-- This section is used to show multiple icon -->
      <ng-container *ngIf="column?.actionType === 'toggleAction'">
        <!-- For more than one icon we used mat-menu -->
        <app-icon
          name="moreIcon"
          [matMenuTriggerFor]="menu"
          class="theme-svg-icon"
        ></app-icon>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let option of column?.actions">
            <ng-container *ngIf="option?.type !== 'deActivate'">
              <button mat-menu-item (click)="sendRow(row, option?.type)">
                {{ option?.text }}
              </button>
            </ng-container>

            <ng-container *ngIf="option?.type === 'deActivate'">
              <!--  -->
              <ng-container *ngIf="row?.status === 'ACTIVE'">
                <button
                  mat-menu-item
                  (click)="sendRow(row, (option.type = 'deActivate'))"
                >
                  {{ "De-Activate" }}
                </button>
              </ng-container>
              <!--  -->
              <ng-container *ngIf="row?.status === 'DEACTIVE'">
                <button
                  mat-menu-item
                  (click)="sendRow(row, (option.type = 'Activate'))"
                >
                  {{ "Activate" }}
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-menu>
      </ng-container>

      <!-- This section is used to show multiple icon -->
      <ng-container *ngIf="column?.actionType === 'cancelAction'">
        <ng-container *ngIf="row?.status === 'PENDING'; else elseBlock">
          <app-icon
            name="moreIcon"
            [matMenuTriggerFor]="menu"
            class="theme-svg-icon"
          ></app-icon>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let option of column?.actions">
              <ng-container *ngIf="option?.type !== 'deActivate'">
                <button mat-menu-item (click)="sendRow(row, option?.type)">
                  {{ option?.text }}
                </button>
              </ng-container>

              <ng-container *ngIf="option?.type === 'deActivate'">
                <!--  -->
                <ng-container *ngIf="row?.status === 'ACTIVE'">
                  <button
                    mat-menu-item
                    (click)="sendRow(row, (option.type = 'deActivate'))"
                  >
                    {{ "De-Activate" }}
                  </button>
                </ng-container>
                <!--  -->
                <ng-container *ngIf="row?.status === 'DEACTIVE'">
                  <button
                    mat-menu-item
                    (click)="sendRow(row, (option.type = 'Activate'))"
                  >
                    {{ "Activate" }}
                  </button>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-menu>
        </ng-container>
        <!-- For more than one icon we used mat-menu -->
        <ng-template #elseBlock>
          {{ "-" }}
        </ng-template>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns?.length">
      <span class="no-data"> <strong>No Data Found.</strong></span>
    </td>
  </tr>
</table>


import { user } from './../models/user.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app-config';
import { ApiInterfaceServices } from './api-interface.service';
import { CommonService } from './common.service';
import { UtilityService } from './utility.service';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;

  constructor(
    private apiService: ApiInterfaceServices,
    private utility: UtilityService,
    private commonService: CommonService,
    private router: Router
  ) {}

  /**
   * Makes a login request with the provided credentials.
   * Returns if login was successful or not.
   *
   * @param objLogin - User's email and password.
   */
  async login(objLogin: user) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService.post(AppConfig.AUTH.LOGIN_API, objLogin)
      ));
      if (result) {
        this.utility.setLocalStorageData(
          'auth-token',
          result['data']['access_token']
        );
        this.utility.setLocalStorageData(
          'refresh-token',
          result['data']['refresh_token']
        );
        return result;
      }
      return false;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Makes a Forgot password request.
   * @param objForgotPassword - User's uuid.
   */
  async forgotPassword(objForgotPassword: user) {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService
          .post(AppConfig.AUTH.FORGOT_PASSWORD, objForgotPassword, true)
      ));
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

   /**
   * Makes a reset password request.
   * @param objChangePassword - User's uuid.
   */
   async changePassword(objChangePassword: user) {
    try {
      const result = await (<any> lastValueFrom(
        this.apiService
          .put(AppConfig.AUTH.CHANGE_PASSWORD, objChangePassword, true)
      ));
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  /**
   * Checks if a user exists in local storage.
   *
   * @returns True if user is considered logged in, false otherwise.
   */
  isUserLoggedIn(): boolean {
    const token = this.utility.getLocalStorageData('auth-token');
    if (token) {
      return true;
    }
    return false;
  }


  /**
   * get current login user data!!!
   */
  async getUserData() {
    try {
      const result = await (<any> firstValueFrom(
        this.apiService.get(AppConfig.USERS.USERME, true)
      ));
      if (result) {
        return result;
      }
      return false;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  /**
   * refresh token api when access token expired
   * new access token and refresh token
   */
  async refreshToken(): Promise<any> {
    try {
      const result = await (<any> firstValueFrom(this.apiService.get(AppConfig.AUTH.GET_TOKEN, true)))
      if (result && result?.status !== 'error') {
        return result;
      } else {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    } catch (error: any) {
      console.log(error);
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }
}

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, EMPTY, from, map, Observable, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { CommonService } from './common.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInteceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private commonservice: CommonService,
    private utilityService: UtilityService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((result) => {
        return result;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        let message = error.error.message ? error.error.message : error.message;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${message}`;
          return throwError(errorMsg);
        }
        switch (error.status) {
          case 400:
            /// Bad Request
            errorMsg = message;
            break;

          case 401:
            /// Not Authorized
            this.commonservice.showErrorToaster(`Error`, `${message}`);
            this.utilityService.clearAllLocalStorageData();
            this.router.navigate(['/login']);
            // return EMPTY; //we are handling for the login btn animation so hide this
            break;
          case 402:
            //ReAuthenticate User
            return this.handleRefreshToken(request,next);
          case 403:
            /// Access Denied
            this.commonservice.showErrorToaster(`Error`, `${message}`);
            this.router.navigate(['/pages/dashboard']);
            return EMPTY;

          case 500:
            /// Internal server error
            errorMsg = error.message;
            break;

          case 404:
            this.commonservice.showErrorToaster(`Error`, `${message}`);
            break;
        }
        return throwError(errorMsg);
      })
    );
    // /**
    //  * Get token from local storage
    //  */
    // let token = localStorage.getItem('login-token');

    // /**
    //  * If token found, then add token to headers of HttpRequest
    //  */
    // if (token) {
    //   req = req.clone({
    //     headers: req.headers.set('Authorization', `Bearer ${token}`),
    //   });
    // }

    // return next.handle(req);
  }

  handleRefreshToken(request: HttpRequest<any>,next: HttpHandler){
    return from((this.authService.refreshToken())).pipe(
      switchMap((token:any)=>{
          this.utilityService.setLocalStorageData('auth-token',token['data']['access_token']);
          this.utilityService.setLocalStorageData('refresh-token',token['data']['refresh_token']);
          return next.handle(this.addTokenHandler(request, token['data']['access_token']))
      }),
      catchError(error => {
        // Handle the error and stop the execution flow
        console.error('Failed to refresh token:', error);
        localStorage.clear();
        this.router.navigate(['login']);
        return throwError(error);
      })
    )
  }

  addTokenHandler(request: HttpRequest<any>, token:string){
    return request.clone({headers:request.headers.set('Authorization', `Bearer ${token}`)})
  }
}

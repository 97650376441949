import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityService } from '../../services/utility.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {
  constructor(private utilityService: UtilityService, 
    private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /**
            * get token form local storage
    */
    let token = this.utilityService.getLocalStorageData('auth-token');

    /**
       * if token then redirect to new routes
       */
    if (token) {
      return true;
    }

    /**
       * if token not found then redirect to login page
       */
    this.router.navigate(['login']);
    return false;
  }
  
}

import {
  Component,
} from '@angular/core';
import { sideMenuData } from '../../models/sidemenu.model';
import { CommonService } from '../../services/common.service';
import { UtilityService } from '../../services/utility.service';
import {
  NavigationStart,
  Router,
} from '@angular/router';
import { EnumRoutes, EnumRouteFunctionArguments } from '../../enum/router.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  /**
   * Side menu interface created and assign structure to array
   */
  arrSidemenuData: sideMenuData[];
  showSubMenuIndex!: number;
  showActiveMenuIndex!: number;
  activeSubMenuIndex!: number; //set active submenu index

  userRole!: string; //For Getting User Role

  userData: any = []; //For getting userdata from localstorage
  displayUserName!: string; // For displayuser name
  userProfileText!: string;

  constructor(
    private commonservice: CommonService,
    public router: Router,
    private utility:UtilityService
  ) {
    this.arrSidemenuData = [];
    //get sidemenu data
    this.arrSidemenuData = this.commonservice.getSidemenuData();
    this.userData = this.commonservice.getUserData();
    this.displayUserName =
      this.userData?.['first_name'] + ' ' + this.userData?.['last_name'];
    this.userProfileText =
      this.userData?.first_name?.charAt(0) +
      this.userData?.last_name?.charAt(0);

    //when route changes call this for active menu index
    this.subscribeToEvents(this.router.url);

    //Call when any router event changes like browser back button click at time not working so call this function
    this.router.events.subscribe((events) => {
      if (events instanceof NavigationStart) {
        //getting browser back btn event trigger
        if (events.navigationTrigger === 'popstate') {
          this.subscribeToEvents(events.url);
        }
      }
    });
  }

  /**
   * use for highlight the sidebar menu and sub menu indexes
   * @param url current routing url
   */
  subscribeToEvents(url: string) {
    switch (true) {
      case url.includes(EnumRoutes.DASHBOARD): {
        this.findActiveMenuIndex(EnumRouteFunctionArguments.DASHBOARD);
        break;
      }
      case url.includes(EnumRoutes.USER_MANAGEMENT): {
        this.findActiveMenuIndex(EnumRouteFunctionArguments.USER_MANAGEMENT);
        break;
      }
      case url.includes(EnumRoutes.CHANGE_PASSWORD): {
        this.findActiveMenuIndex(EnumRouteFunctionArguments.CHANGE_PASSWORD);
        break;
      }
      case url.includes(EnumRoutes.DATA_MANAGEMENT): {
        this.findActiveMenuIndex(EnumRouteFunctionArguments.DATA_MANAGEMENT);
        break;
      }
      case url.includes(EnumRoutes.LANGUAGE_MANAGEMENT): {
        this.findActiveMenuIndex(EnumRouteFunctionArguments.LANGUAGE_MANAGEMENT);
        break;
      }
      default: {
        break;
      }
    }
  }

   /**
   *
   * @param url current routing url
   * cases for master sidebar menu
   */
   dataManagementMenuCases(url: string) {
    switch (true) {
      case url.includes(EnumRoutes.UPLOAD_DATA): {
        this.findActiveMenuIndex(EnumRouteFunctionArguments.UPLOAD_DATA);
        break;
      }
      case url.includes(EnumRoutes.LIST_DATA): {
        this.findActiveMenuIndex(EnumRouteFunctionArguments.LIST_DATA);
        break;
      }
      default: {
        break;
      }
    }
  }


  /**
   * for Getting Index Value of Menu
   */
  findActiveMenuIndex(routingURL: string) {
    this.arrSidemenuData.map((sideMenuData: sideMenuData, index) => {
      if (sideMenuData.urls?.includes(routingURL)) {
        this.showActiveMenuIndex = index;
        this.activeSubMenuIndex = -1;
      }
      if (
        sideMenuData.options &&
        sideMenuData.options?.length != 0 &&
        this.activeSubMenuIndex !== -1
      ) {
        sideMenuData.options?.map((submenuData: any, subindex) => {
          if (submenuData?.urls?.includes(routingURL)) {
            this.showActiveMenuIndex = index;
            this.showSubMenuIndex = index;
            this.activeSubMenuIndex = subindex;
          }
        });
      }
    });
  }

  /**
   * @param index get index value
   * show hide drop down to show/hide sub options
   */
  showHideDropDown(index: number): void {
    //use when there is no submenu
    if (this.arrSidemenuData[index].options?.length == 0) {
      this.showActiveMenuIndex = index;
    }

    if (index === this.showSubMenuIndex) {
      this.showSubMenuIndex = -1;
    } else {
      this.showSubMenuIndex = index;
    }

    this.activeSubMenuIndex = -1;
  }

  /**
   * @param subMenuDetails : getting submenudetails Object
   * @param subMenuIndex : getting submenu index value
   * for getting the submenu details for logout and setting active submenu index
   */
  activeSubMenuData(
    subMenuDetails: any,
    subMenuIndex: number,
    index: number
  ): void {
    //set the index value of menu and submenu
    this.activeSubMenuIndex = subMenuIndex;
    this.showActiveMenuIndex = index;
    this.showSubMenuIndex = index;
   
    //Call logout functionality
    if (subMenuDetails.name === 'LOGOUT') {
      this.logout();
    }
  }

  /**
   * logout auth service method call
   */
  logout() {
    this.utility.clearAllLocalStorageData();
    this.router.navigate(['/login']);
  }

 
}

export enum EnumRoutes{
    DASHBOARD = '/pages/dashboard',
    USER_MANAGEMENT ='/pages/user-management',
    DATA_MANAGEMENT ='/pages/data-management',
    LANGUAGE_MANAGEMENT ='/pages/language-management',
    UPLOAD_DATA ='upload-data',
    LIST_DATA ='list-data',
    CHANGE_PASSWORD = '/pages/change-password'
}

export enum EnumRouteFunctionArguments{
    DASHBOARD = 'dashboard',
    USER_MANAGEMENT = 'users-list',
    DATA_MANAGEMENT = 'data-management',
    LANGUAGE_MANAGEMENT = 'language-management',
    LIST_DATA = 'list-data',
    UPLOAD_DATA = 'upload-data',
    CHANGE_PASSWORD = 'change-password'

    
}

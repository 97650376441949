<div class="page-not-found container">
  <div class="img">
    <img src="/assets/images/Error404.gif" class="gif" loop="infinity" />
    <div class="btn">
      <button class="primary" mat-flat-button (click)="redirect()">
        Back to Home
      </button>
    </div>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-info',
  templateUrl: './generic-info.component.html',
  styleUrls: ['./generic-info.component.scss']
})
export class GenericInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
   
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {}

  /**
   * Copy Login Details button event
   */
  async close(value: string) {
    this.dialogRef.close(value);
  }
}

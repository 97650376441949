import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');
export const AppConfig = {
  AUTH: {
    LOGIN_API: 'auth/login',
    RESET_API : 'auth/password',
    CHANGE_PASSWORD:'auth/change_password',
    FORGOT_PASSWORD:'auth/forgot_password',
    GET_TOKEN:'auth/get_token',
    LOGOUT: 'user/logout',
  },
  USERS: {
    USER: 'user',
    AUTHENTICATE:'verify',
    RESET_PASSWORD:'user/password',
    COUNTRY:'country',
    STATE:'state',
    USERME: 'user/me',
    STATUS:'user/status',
    UPDATEPASSWORD:"user/change",
  },
  DASHBOARD:{
    DASHBOARD:'dashboard'
  },
  DATA_MANAGEMENT:{
    UPLOAD:'file/upload',
    FILE:'file',
    DOWNLOAD_FILE:'download',
    FOLDER:{
      SAVE:'folder/save',
      LANGUAGE_LISTING:'folder/language',
      LISTING:'folder',
      CONFIG:'folder/config'
    }
  },
  LANGUAGE_MANAGEMENT:{
    LANGUAGE:'language'
  }
};

<div class="w-100">
  <div class="employee-profile-header-details row" >
      <div class="left-profile row">
          <div class="menu-icon">
              <button (click)="navToggle()">
                  <span [ngClass]="isMenuShowing?'close menu':'close'"></span>
              </button>
          </div>
          <div class="icon">
              <app-icon name="{{headerData.icon}}" class="theme-svg-icon">
              </app-icon>
          </div>
          <div class="profile-details" >
              <h2 class="tb-fw-bold">{{ 'SIDEBAR.' + headerData.label | translate }}</h2>
              <div class="row">
              <div class="breadcrumb" *ngFor="let breadcrumb of headerData.breadcrumbs; let i = index">
                <custom-buttons shape="link" (click)="breadcrumbrouting(breadcrumb.url)" class="breadcrumb-items tb-fw-semibold" isrippleeffect = "true">{{breadcrumb.label | translate}}</custom-buttons>
            </div>
            </div>
          </div>
      </div>
  </div>
</div>
